export const convertUTCtoIST = (utcDate: string): string => {
  const date = new Date(utcDate);

  // Correct options for Intl.DateTimeFormat with 24-hour format
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "Asia/Kolkata", // IST Time Zone
    year: "numeric", // Full numeric year (e.g., 2025)
    month: "2-digit", // 2-digit month (e.g., 01)
    day: "2-digit", // 2-digit day (e.g., 25)
    hour: "2-digit", // 2-digit hour (e.g., 09, 12)
    minute: "2-digit", // 2-digit minute (e.g., 09, 15)
    second: "2-digit", // Optional: 2-digit seconds (e.g., 00)
    hour12: false, // 24-hour format (no AM/PM)
  };

  const formatter = new Intl.DateTimeFormat("en-GB", options);

  // Format the date and return the result in the format "DD/MM/YYYY HH:MM"
  const formattedDate = formatter.format(date);

  // Modify the formatted date to get the format exactly as "DD/MM/YYYY HH:MM"
  return formattedDate.replace(",", "");
};
