import * as XLSX from "xlsx";
import { convertUTCtoIST } from "./dateUtils";

// Function to flatten inAppParams object fields recursively
export const flattenObject = (
  obj: any,
  prefix = "",
): { [key: string]: any } => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      const fullKey = prefix ? `${prefix}.${key}` : key;

      // Exclude specific keys, e.g., inAppParams.data
      if (fullKey === "inAppParams.data") {
        return acc;
      }

      const value = obj[key];
      if (value && typeof value === "object" && !Array.isArray(value)) {
        Object.assign(acc, flattenObject(value, fullKey));
      } else {
        acc[fullKey] = value;
      }

      // Check if the value is `isWinner` and transform true/false to "Yes"/"No"
      if (fullKey === "isWinner") {
        acc[fullKey] = value ? "Yes" : "No";
      } else if (value && typeof value === "object" && !Array.isArray(value)) {
        Object.assign(acc, flattenObject(value, fullKey));
      } else {
        acc[fullKey] = value;
      }

      return acc;
    },
    {} as { [key: string]: any },
  );
};

export const filterData = (data: any[], whitelist: string[]) => {
  return data.map((item) => {
    const filteredItem: { [key: string]: any } = {};
    whitelist.forEach((key) => {
      if (item[key] !== undefined) {
        if (key === "createdAt") {
          filteredItem["timestamp"] = convertUTCtoIST(item[key]);
        } else if (key === "inAppParams" && typeof item[key] === "object") {
          // Flatten and add inAppParams fields
          const flattenedParams = flattenObject(item[key], "inAppParams");
          Object.assign(filteredItem, flattenedParams);
        } else if (key === "isWinner") {
          // Convert `isWinner` true/false to "Yes"/"No"
          filteredItem[key] = item[key] ? "Yes" : "No";
        } else {
          // Add the field value directly
          filteredItem[key] = item[key];

          // Replace "default" with "NA" for fullName and email
          if (
            (key === "fullName" || key === "email") &&
            item[key] === "default"
          ) {
            filteredItem[key] = "NA";
          }
        }
      }
    });
    return filteredItem;
  });
};

export const exportToExcel = (data: any[], fileName: string) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert data to worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Append worksheet to workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate XLSX file and trigger download
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
